import React from "react"
import Layout from "../components/Layout/Layout"
import Header from "../components/Layout/Header"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { graphql } from "gatsby"
import Seo from "../components/Layout/Seo"
import ImageFromNewt from "../components/Atomic/ImageFromNewt"
import GetRecipeImage from "../components/Atomic/Top/GetRecipeImage"
import GetLifestyleImage from "../components/Atomic/Top/GetLifestyleImage"
import BlackButton from "../components/Atomic/button/BlackButton"


export default function specialPost({data, pageContext }) {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  const {specialFeatureName,description,specialFeaturePosts} = pageContext.post
const allRecipe = data

function displayImage(cover){
  if(cover.coverImage != null){
    return(
    <ImageFromNewt src={cover.coverImage.src} alt={cover.specialFeatureName}/>
    )
  }
}
  
  return (
    <div>
      <Layout>
        <Header />
        <div className="max-w-screen-md px-6 m-auto pt-12">
        <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" / "
            crumbLabel={specialFeatureName}
          />
          <article className="py-20">
            <div className="grid gap-8 place-items-center mb-8">
              <span className="inline-block bg-omcBlueGray text-white font-gothic rounded-full px-4">特集</span>
              <h1 className="md:text-3xl text-2xl font-gothic text-omcBlueGray text-center leading-loose">{specialFeatureName}</h1>
            </div>
           {displayImage(pageContext.post)}
           <div className="pt-8 mb-12">{description}</div>
           <div className="grid gap-8">
           {specialFeaturePosts.map((post,index)=>{
            return(
              <div key={index}>
               {
               post.data.specialFeature_recipe && 
               <div className="grid md:grid-cols-3 md:gap-8 gap-4 border-b pb-8">
                <GetRecipeImage recipeData={allRecipe.allNewtRecipe} searchTitle={post.data.specialFeature_recipe.recipeTitle} className={"rounded-lg custom-shadow-md"} />
                <div className="md:col-span-2 grid content-between">
                <h2 className="pb-8">{post.data.specialFeature_recipe.recipeTitle}</h2>
                <div className=" justify-self-end"><BlackButton text={"レシピを見る"} className={"text-sm"} link={`/recipe/${post.data.specialFeature_recipe.slug}`}/></div>
                </div>
              </div>
               }
  
               {
               post.data.specialFeature_lifestyle && 
               <div className="grid md:grid-cols-3 md:gap-8 gap-4 border-b pb-8">
               <GetLifestyleImage title={post.data.specialFeature_lifestyle.title} className={"rounded-lg custom-shadow-md"}/>
               <div className="md:col-span-2 grid content-between">
               <h2 className="pb-8">{post.data.specialFeature_lifestyle.title}</h2>
               <div className=" justify-self-end"><BlackButton text={"記事を読む"} className={"text-sm"} link={`/life-style/${post.data.specialFeature_lifestyle.slug}`}/></div>
               </div>
               </div>
               }
              </div>
             
            )
           })}
           </div>
          </article>
          <div className="flex justify-center pb-32"><BlackButton text={"特集一覧へ戻る"} link={"/special-feature/"}/></div>
          
        </div>
      </Layout>
    </div>
  )
}

export const recipeData = graphql`
query recipeData {
  allNewtRecipe {
    edges {
      node {
        eyecatch {
          src
        }
        recipeTitle
      }
    }
  }
}

`

export const Head = ({pageContext}) => (
  <>
<Seo title={pageContext.post.specialFeatureName} description={pageContext.post.description} ogpImgPath={pageContext.post.coverImage != null ? pageContext.post.coverImage.src:""}/>
</>
)
